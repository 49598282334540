<template>
  <div id="app">
    <!-- Navbar -->
    <app-navbar></app-navbar>
    
    <!-- Main app body -->
    <div class="container" :class="{'is-fluid': appSetupStore.isFullWidth}">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
    <!-- Footer -->
    <footer class="udp-footer" role="contentinfo">
      <div class="columns">
        <!-- Address/Contact/Version -->
        <div class="column is-two-thirds-desktop">
          <p tabindex="0"><strong>Mailing address:</strong> <br class="is-hidden-desktop">6100 Main St MS-208, Houston, Texas 77005-1827 | or P.O. Box 1892, Houston, Texas 77251-1892</p>
          <p tabindex="0"><strong>Physical address:</strong> <br class="is-hidden-desktop">Rice University | Kraft Hall | 6100 Main Street, Suite 305, Houston, TX 77005-1892</p>
          <p tabindex="0"><strong>Phone:</strong> <br class="is-hidden-desktop">713-348-4132</p>
          <p tabindex="0"><strong>Site Version: </strong> <br class="is-hidden-desktop">{{ versionNumber }}</p>
          <p tabindex="0"><strong><a href="https://privacy.rice.edu/privacy" target="_blank" rel="noopener noreferrer" tabindex="-1">Privacy Policy</a></strong></p>
        </div>
        <!-- Kinder Logo -->
        <div class="column is-one-third-desktop has-text-centered">
          <a href="http://kinder.rice.edu" title="Kinder Institute logo" target="_blank" rel="noopener noreferrer" tabindex="-1">
            <img src="./assets/footer-logo.png" alt="Kinder Institute logo" title="Kinder Institute logo" border="0">
          </a>
        </div>
      </div>
    </footer>

    <!-- Sign In/Forgot Password/Reset Password Modal -->
    <transition name="popup">
      <div tabindex="-1" role="dialog" aria-hidden="true" class="modal is-active px-2" v-if="appSetupStore.isSignInModalOpen" @keydown.escape="appSetupStore.isSignInModalOpen = false ">
        <div class="modal-background"></div>
        <div class="modal-card">
          <header class="modal-card-head is-radiusless">
            <p class="modal-card-title">{{ modalTitle }}</p>
            <button class="delete" @click="toggleSignInModal()"></button>
          </header>
          <section class="modal-card-body">
            <transition name="modal-fade" mode="out-in">
              <sign-in v-if="appSetupStore.modalType === 'signIn'"></sign-in>
              <forgot-password v-else-if="appSetupStore.modalType === 'forgotPassword'"></forgot-password>
              <reset-password v-else-if="appSetupStore.modalType === 'resetPassword'"></reset-password>
            </transition>
          </section>
        </div>
      </div>
    </transition>
    <!-- Lookup Info Modal -->
    <lookup-info-modal :category="appSetupStore.lookupCategory"></lookup-info-modal>
    <!-- Column Description Modal -->
    <lookup-single-value-modal :modalTitle="appSetupStore.singleValueModal.modalTitle" :category="appSetupStore.singleValueModal.category" :value="appSetupStore.singleValueModal.value"></lookup-single-value-modal>
  </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useDatasetCatalogStore } from '@/store/datasetCatalog'
import { useAuthenticationStore } from '@/store/authentication'
import { useAppSetupStore } from '@/store/appSetup'
// Modals
const SignIn = () => import('./components/modals/SignIn.vue')
const ForgotPassword = () => import('./components/modals/ForgotPassword.vue')
const ResetPassword = () => import('./components/modals/ResetPassword.vue')
const lookupSingleValueModal = () => import('./components/modals/lookupSingleValueModal.vue')
const lookupInfoModal = () => import('./components/modals/lookupInfoModal.vue')
const appNavbar = () => import('./components/appNavbar.vue')

export default {
  name: 'app',
  metaInfo: {
      titleTemplate: '%s | Urban Data Platform - Kinder Institute for Urban Research',
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      ]
  },
  components: {
    SignIn,
    ForgotPassword,
    ResetPassword,
    appNavbar,
    lookupSingleValueModal,
    lookupInfoModal
  },
  data () {
    return {
      modalTitle: null
    }
  },
  computed: {
    ...mapStores(
      useDatasetCatalogStore, 
      useAuthenticationStore, 
      useAppSetupStore
    ),
    versionNumber () {
      return process.env.VUE_APP_VERSION
    }
  },
  methods: {
    toggleSignInModal () {
      // If open, set to false
      if (this.appSetupStore.isSignInModalOpen) {
        this.appSetupStore.isSignInModalOpen = false 

        if (this.authenticationStore.postAuthRoutePath) {
          // Clear post auth route path and go to Home
          this.authenticationStore.postAuthRoutePath = null
          this.$router.replace('/')
        }
      } else {
        // if closed, set to true to open
        this.appSetupStore.isSignInModalOpen = true
      }
    },
  },
  created () {
    // If in local development, don't log
    if (this.appSetupStore.appEnv !== 'development' || process.env.VUE_APP_ENV.trim() !== 'development') {
      // Log session creation
      let target = this.$router.currentRoute.params.id
        ? this.$router.currentRoute.params.id
        : null

      this.appSetupStore.logger({
        location: this.$router.currentRoute.name,
        action: 'Session Start',
        target: target
      })
    }

    // Make request for array of datasets in store
    this.datasetCatalogStore.loadDatasets()
    // Get Organizations obj
    this.datasetCatalogStore.getOrganizations()
    // Get Organizations obj
    this.appSetupStore.getLookups()

    this.$router.beforeResolve(async (to, from, next) => {
      // This code will run before navigating to the next page
      // If in local development, don't log activity
      if (this.appSetupStore.appEnv !== 'development' || process.env.VUE_APP_ENV.trim() !== 'development') {
        this.appSetupStore.logger({
          'location': from.name,
          'action': 'Open',
          'target': to.name
        })
      }

      window.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth'
      })

      // If user is signed in, but not verified redirect them to Verify page and keep them there
      if (this.authenticationStore.isSignedIn && !this.authenticationStore.userAttributes.email_verified && to.path !== '/register/verify') {
        next('/register/verify')
      } else next()
    })
  }
}
</script>
<style>
/* Global */
/* Make links darker on hover*/
a:hover {
  color: #00205c;
}
</style>
<style scoped>
/* Footer */
.udp-footer {
  font-size: 0.75em;
  padding: 16px;
  border-top: 1px dotted #ccc;
}
.column p {
  margin: 4px;
}

/* Padding for main router-view for most components */
.container {
  padding: 24px 20px;
}

/* Transition for moving between pages/components on the site */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

/* Transition for modal */
.popup-enter-active {
  transition: all 0.2s ease;
}
.popup-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.popup-enter {
  transform: translateY(20px);
  opacity: 0;
}
.popup-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}

/* Transition for moving between modals */
.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: all 0.2s ease;
}
.modal-fade-enter {
  transform: translateX(-20px);
  opacity: 0;
}
.modal-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

/* Drop Down Animation */
@media screen and (min-width: 1024px) .navbar-dropdown.is-boxed {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  -webkit-transition-duration: 87ms;
  transition-duration: 87ms;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform;
}
/* IE11 modal fix */
@media screen and (min-width: 769px) {
  .modal-card {
    margin: 0 20px !important;
  }
}
</style>
